const b = document.getElementById("na")!;
b.addEventListener(
  "click",
  () => {
    void import("./bg").then(async ({ init, initAudio }) => {
      b.classList.add("s");
      document.body.classList.add("s");
      await init();
      initAudio();
    });
  },
  { once: true },
);
